<template>
  <div id="MainView">
    <b-navbar toggleable="lg" type="dark" class="px-4 py-3">
      <b-nav-form class="navy-fields">
        <b-input-group class="rounded-pill">
          <template #prepend>
            <b-input-group-text class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control">
              <font-awesome-icon icon="search" />
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control">
              <b-badge variant="light" pill>
                {{ filteredList('high').length + filteredList('intermediate').length + filteredList('low').length + filteredList('other').length + filteredList('nothing').length | formatInt }}
                of {{ total | formatInt }}
              </b-badge>
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="search"
            placeholder="Search for patient in loaded studies ..."
            style="width: 25rem;"
          ></b-form-input>
        </b-input-group>
        <b-button @click.prevent="loadMore()" pill variant="primary" size="sm" class="ml-2 px-3 text-white font-weight-400" v-if="loadedTotal < total">
          <font-awesome-icon icon="download" size="xs" />
          Download more
        </b-button>
      </b-nav-form>
      <b-nav-form class="navy-fields ml-3">
        <b-button-group size="sm">
          <b-button :variant="model === 'Pneumonia' ? 'primary' : 'dark'" @click="model = 'Pneumonia'">Pneumonia</b-button>
          <b-button :variant="model === 'Tuberculosis' ? 'primary' : 'dark'" @click="model = 'Tuberculosis'">Tuberculosis</b-button>
          <b-button :variant="model === 'General' ? 'primary' : 'dark'" @click="model = 'General'">General Chest</b-button>
        </b-button-group>
      </b-nav-form>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav class="mr-4">
            <b-nav-item href="https://envisionit.ai/support/" target="_blank" link-classes="btn px-4 btn-sm btn-yellow rounded-pill">
              Contact us
            </b-nav-item>
          </b-navbar-nav>
          <!--
          <b-nav-item-dropdown right no-caret>
            <template v-slot:button-content>
              <font-awesome-icon icon="cog"></font-awesome-icon>
            </template>
            <b-dropdown-item href="#">Item 1</b-dropdown-item>
            <b-dropdown-item href="#">Item 2</b-dropdown-item>
            <b-dropdown-item href="#">Item 3</b-dropdown-item>
            <b-dropdown-item href="#">Item 4</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-navbar-nav>
            <b-nav-item href="#">
              <font-awesome-icon icon="bell"></font-awesome-icon>
            </b-nav-item>
          </b-navbar-nav>
          <a href="#" class="profile-icon box-shadow float-right ml-3">
            <span
              class="profile-img"
              :style="'background-image: url(' + require('@/assets/images/avatar-placeholder.jpg') + ')'"
            ></span>
            <span class="initials">JS</span>
          </a>
          -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="px-4 inner-container">
      <h5 class="pl-4 mt-2 title">
        Results
        <small>
          <a v-b-tooltip.hover.top="'Sort by AI decision.'" href="#" @click.prevent="sorting = 'priority'"><font-awesome-icon icon="sort-amount-up" size="xs" class="ml-1" :class="sorting !== 'priority' ? 'color-white' : null" /></a>
          <a v-b-tooltip.hover.top="'Sort by date.'" href="#" @click.prevent="sorting = 'date'"><font-awesome-icon icon="calendar" size="xs" class="ml-1" :class="sorting !== 'date' ? 'color-white' : null" /></a>
        </small>
      </h5>
      <b-row class="inner-row">
        <b-col cols="4" class="inner-col d-flex flex-column">
          <!-- Accordion -->
          <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist" v-if="sorting === 'priority'">
            <!-- Accordion 1 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('high')">
              <b-button block v-b-toggle.accordion-1 class="py-1">
                <b-badge pill class="indicator normal-font high border-0">
                  <font-awesome-icon class="mr-1" icon="exclamation-triangle"></font-awesome-icon> {{ getModalDescription('high') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('high').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('high')">
              <list-view :list="filteredList('high')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 1 -->

            <!-- Accordion 2 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('intermediate')">
              <b-button block v-b-toggle.accordion-2 class="py-1">
                <b-badge pill class="indicator normal-font moderate border-0">
                  <font-awesome-icon class="mr-1" icon="exclamation-triangle"></font-awesome-icon> {{ getModalDescription('intermediate') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('intermediate').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('intermediate')">
              <list-view :list="filteredList('intermediate')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 2 -->

            <!-- Accordion 3 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('low')">
              <b-button block v-b-toggle.accordion-3 class="py-1">
                <b-badge pill class="indicator normal-font moderate border-0">
                  <font-awesome-icon class="mr-1" icon="exclamation-triangle"></font-awesome-icon> {{ getModalDescription('low') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('low').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('low')">
              <list-view :list="filteredList('low')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 3 -->

            <!-- Accordion 4 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('other')">
              <b-button block v-b-toggle.accordion-4 class="py-1">
                <b-badge pill class="indicator normal-font nothing border-0">
                  <font-awesome-icon class="mr-1" icon="heartbeat"></font-awesome-icon> {{ getModalDescription('other') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('other').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('other')">
              <list-view :list="filteredList('other')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 4 -->

            <!-- Accordion 5 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('none')">
              <b-button block v-b-toggle.accordion-5 class="py-1">
                <b-badge pill class="indicator normal-font nothing border-0">
                  <font-awesome-icon class="mr-1" icon="heartbeat"></font-awesome-icon> {{ getModalDescription('none') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('nothing').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('none')">
              <list-view :list="filteredList('nothing')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 5 -->

            <!-- Accordion 6 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.accordion-6 class="text-center color-yellow font-weight-900">
                Add Images
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <upload-files v-on:refresh-studies="refreshStudies"></upload-files>
            </b-collapse>
            <!-- END | Accordion 6 -->
          </div>
          <!-- END | Accordion -->
          <!-- Accordion -->
          <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist" v-if="sorting === 'date'">
            <!-- Accordion 1 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.accordion-1 class="py-2">
                Just now
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('now').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :list="filteredList('now')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 1 -->

            <!-- Accordion 2 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.accordion-2 class="py-2">
                Ealier Today
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('earlier').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :list="filteredList('earlier')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 2 -->

            <!-- Accordion 3 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.accordion-3 class="py-2">
                Yesterday
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('yesterday').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :list="filteredList('yesterday')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 3 -->

            <!-- Accordion 4 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.accordion-4 class="py-2">
                This week
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('week').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :list="filteredList('week')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 4 -->

            <!-- Accordion 5 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.accordion-5 class="py-2">
                Older
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">
                  {{ filteredList('older').length | formatInt }}
                  of {{ total - loadedThisWeek | formatInt }}
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :list="filteredList('older')" :active="selected" v-on:study-selected="selectStudy"></list-view>
              <b-list-group flush v-if="loadedTotal < total">
                <b-list-group-item href="#" @click.prevent="loadMore()">
                  <p class="mb-0 text-center text-white">
                    Downloaded {{ loadedTotal | formatInt }} of {{ total | formatInt }} images.
                    <font-awesome-icon icon="download" class="ml-1" />
                    Click to download more.
                  </p>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
            <!-- END | Accordion 5 -->

            <!-- Accordion 6 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.accordion-6 class="text-center color-yellow font-weight-900">
                Add Images
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <upload-files v-on:refresh-studies="refreshStudies"></upload-files>
            </b-collapse>
            <!-- END | Accordion 6 -->
          </div>
          <!-- END | Accordion -->
        </b-col>
        <b-col cols="8" class="inner-col d-flex flex-column">
          <result-view v-if="selected" :selected="selectedData" :model="model" :key="selected"></result-view>
          <nothing-view v-else></nothing-view>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { staffSubmissionService } from './../../services'
import { apiRoute, authHeader } from './../../helpers'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import ListView from './components/List'
import NothingView from './components/Nothing'
import ResultView from './components/View'
import UploadFiles from './components/Upload'

export default {
  components: {
    ListView,
    NothingView,
    ResultView,
    UploadFiles
  },
  data () {
    return {
      current: null,
      interval: null,
      period: 'now',
      allLoaded: false,
      selected: null,
      search: null,
      now: [],
      earlier: [],
      yesterday: [],
      week: [],
      older: [],
      high: [],
      intermediate: [],
      low: [],
      other: [],
      nothing: [],
      model: null,
      key: 0,
      sorting: 'date',
      page: 1,
      total: 0
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      submissions: state => state.staffSubmission
    }),
    loadState () {
      return this.submissions.status.loaded
    },
    selectedData () {
      if (!this.selected) {
        return null
      } else {
        let _selected = this.high.find(item => item.created_at._id === this.selected)
        if (_selected) {
          return _selected.created_at.cell
        } else {
          _selected = this.intermediate.find(item => item.created_at._id === this.selected)
          if (_selected) {
            return _selected.created_at.cell
          } else {
            _selected = this.low.find(item => item.created_at._id === this.selected)
            if (_selected) {
              return _selected.created_at.cell
            } else {
              _selected = this.other.find(item => item.created_at._id === this.selected)
              if (_selected) {
                return _selected.created_at.cell
              } else {
                _selected = this.nothing.find(item => item.created_at._id === this.selected)
                if (_selected) {
                  return _selected.created_at.cell
                } else {
                  return null
                }
              }
            }
          }
        }
      }
    },
    loadedTotal () {
      return this.filteredList('high').length + this.filteredList('intermediate').length + this.filteredList('low').length + this.filteredList('other').length + this.filteredList('nothing').length
    },
    loadedThisWeek () {
      return this.filteredList('now').length + this.filteredList('earlier').length + this.filteredList('yesterday').length + this.filteredList('week').length
    }
  },
  methods: {
    ...mapActions('staffSubmission', {
      getCovid: 'getCovid',
      getPneumonia: 'getPneumonia',
      getTuberculosis: 'getTuberculosis',
      getGeneral: 'getGeneral',
      clearSubmissions: 'clear'
    }),
    refreshStudies: function () {
      /*
      this.period = 'now'
      this.getAllSubmissions(this.period)
      */
    },
    filteredList: function (period) {
      if (!this[period]) {
        return []
      } else {
        if (!this.search) {
          return this[period]
        } else {
          return this[period].filter(row => (row.patient.demographics.name || row.patient.identifier).toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || row.patient.identifier.toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
        }
      }
    },
    displayResult: async function (data) {
      this.current = data

      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
      const _response = await fetch(apiRoute() + '/api/v1/staff/covid/heatmaps/' + this.current.submission._id, requestOptions)
      const results = await _response.json()
      this.current.heatmaps = results
    },
    getAllSubmissions: function (period) {
      if (this.model === 'Pneumonia') {
        this.getPneumonia({ period: period, page: period === 'older' ? this.page : 1 })
      } else if (this.model === 'COVID') {
        this.getCovid(period)
      } else if (this.model === 'Tuberculosis') {
        this.getTuberculosis({ period: period, page: period === 'older' ? this.page : 1 })
      } else {
        this.getGeneral({ period: period, page: period === 'older' ? this.page : 1 })
      }
    },
    loadMore: function () {
      clearInterval(this.interval)
      this.interval = null
      this.allLoaded = false
      this.period = 'older'
      this.page += 1
      this.getAllSubmissions(this.period)
    },
    getModalDescription: function (name) {
      if (this.model !== 'General') {
        if (name === 'high') {
          return 'High Probability of ' + this.model
        } else if (name === 'intermediate') {
          if (this.model === 'Tuberculosis') {
            return null
          } else {
            return 'Intermediate Probability of ' + this.model
          }
        } else if (name === 'low') {
          return 'Low Probability of ' + this.model
        } else if (name === 'other') {
          return 'Other Pathologies'
        } else {
          return 'No Pathologies Found'
        }
      } else {
        if (name === 'high') {
          return 'Abnormality score Over 0.75'
        } else if (name === 'intermediate') {
          return 'Abnormality score Between 0.45 and 0.75'
        } else if (name === 'low') {
          return 'Abnormality score Below 0.45'
        } else if (name === 'other') {
          return null
        } else {
          return 'No Pathologies Found'
        }
      }
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getConclusionClass: function (name) {
      if (name.indexOf('high') > -1) {
        return 'text-danger'
      } else if (name.indexOf('intermediate') > -1) {
        return 'text-warning'
      } else {
        return 'text-success'
      }
    },
    getCurrentClass: function (_id) {
      if (this.current && this.current.submission) {
        if (this.current.submission._id === _id) {
          console.log('Selected')
          return 'selected-row'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    originalImage: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].path
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].response.bounding.path
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'cardiomegaly')
        if (_pathology) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    selectStudy: async function (id) {
      this.selected = id
    }
  },
  mounted () {
    const _model = localStorage.getItem('ai_model')
    if (_model) {
      this.model = _model
    } else {
      this.model = 'Tuberculosis'
    }
  },
  destroyed () {
    clearInterval(this.interval)
    this.interval = null
  },
  watch: {
    model: function () {
      localStorage.setItem('ai_model', this.model)

      clearInterval(this.interval)
      this.interval = null
      this.current = null
      this.selected = null
      this.period = 'now'
      this.allLoaded = false
      this.search = null
      this.now = []
      this.earlier = []
      this.yesterday = []
      this.week = []
      this.older = []
      this.high = []
      this.intermediate = []
      this.low = []
      this.other = []
      this.nothing = []
      this.page = 1
      this.total = 0

      this.$nextTick(() => {
        this.getAllSubmissions(this.period)
      })
    },
    loadState (loaded) {
      if (loaded && this.alert.message == null && this.alert.type == null) {
        const getProbability = function (name) {
          if (name.indexOf('high') > -1) {
            return 'high'
          } else if (name.indexOf('intermediate') > -1) {
            return 'intermediate'
          } else if (name.indexOf('low') > -1) {
            return 'low'
          } else if (name.indexOf('other') > -1) {
            return 'other'
          } else {
            return 'nothing'
          }
        }

        const rowData = this.submissions.info[this.period]
        if (rowData) {
          console.log('I am in rowData')
          if (this.period !== 'older' || (this.period === 'older' && this.page <= 1)) {
            this[this.period] = []
          }

          rowData.forEach(row => {
            if (!row.submission.patient) {
              row.submission.patient = {
                _id: row.submission._id,
                clinical: {},
                created_at: row.submission.created_at,
                demographics: {
                  ageGroup: 'Unknown',
                  country: 'Unknown',
                  gender: 'Unknown',
                  province: 'Unknown'
                },
                identifier: row.submission.study_id
              }
            }

            const _record = {
              created_at: { date: moment(row.submission.created_at).toDate(), raw_date: moment(row.submission.created_at), _id: row.submission._id, cell: row },
              conclusion: row.analysis.conclusion ? row.analysis.conclusion.toLowerCase() : 'Unknown',
              type: undefined,
              score: row.analysis.score,
              patient: row.submission.patient,
              feedback: false,
              probability: getProbability(row.analysis.conclusion ? row.analysis.conclusion.toLowerCase() : 'Unknown')
            }

            if (row.submission.attachments && row.submission.attachments.length > 0 && row.submission.attachments[0].feedback) {
              _record.feedback = true
            }

            const _index = this[_record.probability].findIndex(__record => __record.created_at._id === _record.created_at._id)
            if (_index > -1) {
              this.$set(this[_record.probability], _index, _record)
            } else {
              this[_record.probability].push(_record)
            }

            const _index2 = this[this.period].findIndex(__record => __record.created_at._id === _record.created_at._id)
            if (_index2 > -1) {
              this.$set(this[this.period], _index2, _record)
            } else {
              this[this.period].push(_record)
            }
          })

          this[this.period].sort((a, b) => {
            if (a.sort < b.sort) {
              return 1
            } else if (a.sort === b.sort) {
              return (a.created_at.raw_date < b.created_at.raw_date) ? 1 : -1
            } else {
              return -1
            }
          })

          if (this.period === 'now') {
            staffSubmissionService.getCount()
              .then(
                response => {
                  if (response) {
                    this.total = parseInt(response.count)
                  }
                  this.period = 'earlier'
                  this.getAllSubmissions(this.period)
                },
                error => {
                  console.log(error)
                  this.period = 'earlier'
                  this.getAllSubmissions(this.period)
                }
              )
          } else if (this.period === 'earlier') {
            if (!this.allLoaded) {
              this.period = 'yesterday'
              this.getAllSubmissions(this.period)
            }
          } else if (this.period === 'yesterday') {
            if (!this.allLoaded) {
              this.period = 'week'
              this.getAllSubmissions(this.period)
            }
          } else if (this.period === 'week') {
            if (!this.allLoaded) {
              this.period = 'older'
              this.getAllSubmissions(this.period)
            }
          } else {
            this.allLoaded = true
            const self = this
            if (!this.interval) {
              this.interval = setInterval(() => {
                self.period = 'now'
                self.getAllSubmissions(this.period)
              }, 30000)
            }
          }
        } else {
          if (this.submissions.info && this.submissions.info._id) {
            const _id = this.submissions.info._id
            const _record = this.submissions.info[_id]

            const _probabilities = ['high', 'intermediate', 'low', 'other', 'nothing']
            _probabilities.forEach(_probability => {
              const _index = this[_probability].findIndex(__record => __record.created_at._id === _id)
              if (_index > -1) {
                if (!_record.submission.patient) {
                  _record.submission.patient = {
                    _id: _record.submission._id,
                    clinical: {},
                    created_at: _record.submission.created_at,
                    demographics: {
                      ageGroup: 'Unknown',
                      country: 'Unknown',
                      gender: 'Unknown',
                      province: 'Unknown'
                    },
                    identifier: _record.submission.study_id
                  }
                }

                const ___record = {
                  created_at: { date: moment(_record.submission.created_at).toDate(), raw_date: moment(_record.submission.created_at), _id: _record.submission._id, cell: _record },
                  conclusion: _record.analysis.conclusion ? _record.analysis.conclusion.toLowerCase() : _record.analysis.conclusion,
                  type: undefined,
                  score: _record.analysis.score,
                  patient: _record.submission.patient,
                  feedback: false,
                  probability: getProbability(_record.analysis.conclusion.toLowerCase())
                }

                if (_record.submission.attachments && _record.submission.attachments.length > 0 && _record.submission.attachments[0].feedback) {
                  ___record.feedback = true
                }

                console.log(___record.probability, _probability)
                if (___record.probability === _probability) {
                  this.$set(this[_probability], _index, ___record)
                } else {
                  this.$delete(this[_probability], _index)
                  this[___record.probability].push(___record)
                }

                if (this.sorting === 'priority') {
                  this.selected = null
                  this.$nextTick(() => {
                    this.selected = _id
                  })
                }
              }
            })

            const _periods = ['now', 'earlier', 'yesterday', 'week', 'older']
            _periods.forEach(_period => {
              const _index = this[_period].findIndex(__record => __record.created_at._id === _id)
              if (_index > -1) {
                if (!_record.submission.patient) {
                  _record.submission.patient = {
                    _id: _record.submission._id,
                    clinical: {},
                    created_at: _record.submission.created_at,
                    demographics: {
                      ageGroup: 'Unknown',
                      country: 'Unknown',
                      gender: 'Unknown',
                      province: 'Unknown'
                    },
                    identifier: _record.submission.study_id
                  }
                }

                const ___record = {
                  created_at: { date: moment(_record.submission.created_at).toDate(), raw_date: moment(_record.submission.created_at), _id: _record.submission._id, cell: _record },
                  conclusion: _record.analysis.conclusion.toLowerCase(),
                  type: undefined,
                  score: _record.analysis.score,
                  patient: _record.submission.patient,
                  feedback: false,
                  probability: getProbability(_record.analysis.conclusion.toLowerCase())
                }

                if (_record.submission.attachments && _record.submission.attachments.length > 0 && _record.submission.attachments[0].feedback) {
                  ___record.feedback = true
                }

                this.$set(this[_period], _index, ___record)

                if (this.sorting === 'date') {
                  this.selected = null
                  this.$nextTick(() => {
                    this.selected = _id
                  })
                }
              }
            })
          }
        }
      }
    }
  }
}
</script>
