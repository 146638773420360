<template>
  <b-list-group flush>
    <b-list-group-item href="#" @click.prevent="selectStudy(study.created_at._id)" v-for="(study, $index) in list" :key="$index" :active="study.created_at._id === active">
      <div class="text-right float-right">
        <small><em>{{ formatDate(study.created_at.date) }}</em></small>
        <p class="mt-2"><small><em>{{ getPathologies(study) }}</em></small></p>
      </div>
      <h6 class="color-white">
        <b-badge pill class="indicator px-1 mr-2" :class="getIndicator(study.conclusion)">
          <font-awesome-icon :icon="getIcon(study.conclusion)"></font-awesome-icon>
        </b-badge>
        {{ study.patient.demographics.name || study.patient.identifier }}
      </h6>
      <p class="mb-0">
        ID: {{ study.patient.identifier }}<br />
        <span v-if="study.patient.demographics && study.patient.demographics.ageGroup">Age: {{ study.patient.demographics.ageGroup }}<br /></span>
        Date: {{ formatFullDate(study.created_at.date) }}
      </p>
      <b-row class="mt-2">
        <b-col>
          <b-badge pill class="indicator nothing mr-2" v-if="study.feedback">
            <font-awesome-icon icon="comment-dots" class="mr-1"></font-awesome-icon> Feedback recorded
          </b-badge>
          <b-badge pill class="indicator mr-2" :class="report(study).status === 'Completed' ? 'nothing' : 'moderate'" v-if="report(study)">
            <font-awesome-icon icon="file-alt" class="mr-1"></font-awesome-icon> {{ report(study).status === 'In Progress' ? 'Details saved' : report(study).status }}, {{ formatFullDate(report(study).created_at) }}{{ report(study).created_by.personal ? ' by ' + report(study).created_by.personal.fullname : '' }}
          </b-badge>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    active: {
      type: String,
      default: null
    }
  },
  methods: {
    report: function (study) {
      if (study.created_at.cell.report) {
        const _report = study.created_at.cell.report
        if (_report.status) {
          return _report
        } else {
          return null
        }
      } else {
        return null
      }
    },
    selectStudy: function (id) {
      this.$emit('study-selected', id)
    },
    formatDate: function (date) {
      return moment(date).fromNow()
    },
    formatFullDate: function (date) {
      return moment(date).format('D MMM YYYY, HH:mm')
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'exclamation-triangle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'exclamation-triangle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high'
      } else if (name.indexOf('intermediate') > -1) {
        return 'moderate'
      } else if (name.indexOf('low') > -1) {
        return 'moderate'
      } else {
        return 'nothing'
      }
    },
    getPathologies: function (data) {
      if (data && data.created_at && data.created_at.cell) {
        if (data.created_at.cell.report && data.created_at.cell.report.parenchyma && data.created_at.cell.report.parenchyma.length > 0) {
          const _analysis = data.created_at.cell.report.parenchyma
          const _number = _analysis.length
          if (_number === 0) {
            return 'No pathologies'
          } else if (_number === 1) {
            return '1 pathology'
          } else {
            return _number + ' pathologies'
          }
        } else if (data.created_at.cell.analysis) {
          const _analysis = data.created_at.cell.analysis
          if (_analysis && _analysis.pathologies) {
            const _number = _analysis.pathologies.length
            if (_number === 0) {
              return 'No pathologies'
            } else if (_number === 1) {
              return '1 pathology'
            } else {
              return _number + ' pathologies'
            }
          } else {
            return null
          }
        } else if (data.created_at.cell.submission) {
          const _submission = data.created_at.cell.submission
          if (_submission && _submission.attachments && _submission.attachments.length > 0) {
            const _attachment = _submission.attachments[0]
            if (_attachment && _attachment.response && _attachment.response.labels && _attachment.response.labels.length > 0) {
              const _number = _attachment.response.labels[0].pathologies.length
              if (_number === 0) {
                return 'No pathologies'
              } else if (_number === 1) {
                return '1 pathology'
              } else {
                return _number + ' pathologies'
              }
            } else {
              return null
            }
          } else {
            return null
          }
        } else {
          return null
        }
      } else {
        return null
      }
    }
  }
}
</script>
