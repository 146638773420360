<template>
  <b-modal id="modal-fullscreen" modal-class="fullscreen" content-class="bg-dark-navy rounded-0 border-0" header-class="bg-navy rounded-0 border-0" body-class="scroll-overflow" scrollable hide-footer>
    <template v-slot:modal-header="{ close }">
      <b-row class="w-100">
        <b-col>
          <b-media no-body>
            <b-media-aside class="border-right mr-3 pr-3">
              <img :src="require('@/assets/images/logo-icon.svg')" class="m-auto d-block img-fluid" style="max-width:60px" />
            </b-media-aside>
            <b-media-body class="align-self-center">
              <h6 class="color-white">{{ current.submission.patient.demographics.name || current.submission.patient.identifier }}</h6>
              <b-badge pill class="indicator px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
                <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon> {{ getConclusion(current.analysis.conclusion.toLowerCase()) }}
              </b-badge>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="align-self-center text-right pr-0">
          <b-button size="sm" variant="link" class="color-white font-weight-400 p-0" @click="close()">
            Exit Fullscreen<i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-row class="pb-3">
      <b-col cols="7">
          <div class="zoom-container mb-3">
            <img class="fluid" :src="selectedImage" :style="'width: 100%; filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'">
          </div>
          <div class="table-responsive">
            <table class="table table-borderless table-sm color-white mb-0" width="100%">
              <thead>
                <tr class="border-bottom border-blue">
                  <th width="180" class="pl-0 font-size-18">
                    Parenchyma
                  </th>
                  <th class="text-center pl-0">RUZ</th>
                  <th class="text-center pl-0">RMZ</th>
                  <th class="text-center pl-0">RLZ</th>
                  <th class="text-center pl-0">LUZ</th>
                  <th class="text-center pl-0">LMZ</th>
                  <th class="text-center pl-0">LLZ</th>
                  <th class="text-center pl-0">RT</th>
                  <th class="text-center pl-0">LT</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pathology in filteredList" :key="current.submission._id + '_' + pathology.name">
                  <td>
                    <font-awesome-icon :icon="pathology.rt || pathology.lt ? 'check-circle' : 'times-circle'" class="color-yellow mr-2 font-size-18"></font-awesome-icon>
                    {{ pathology.name.charAt(0).toUpperCase() + pathology.name.substring(1) }}
                  </td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.ruz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.ruz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.rmz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.rmz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.rlz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.rlz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.luz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.luz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.lmz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.lmz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.llz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.llz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.rt ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.rt}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.lt ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.lt}"></font-awesome-icon></td>
                </tr>
                <tr v-if="hasCardiomegaly(current.submission)">
                  <td>
                    <font-awesome-icon :icon="hasCardiomegaly(current.submission) ? 'check-circle' : 'times-circle'" class="color-yellow mr-2 font-size-18"></font-awesome-icon>
                    Heart enlagement
                  </td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col cols="5">
          <b-tabs pills class="modal-tabs" nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0 mb-3" content-class="" fill>
            <b-tab active title="Image view">
              <ul class="list-unstyled row mx-n1 heatmap-list">
                <li class="col-6 px-1 mt-2">
                  <a :class="selectedHeatmap === 'original' ? 'active' : ''" href="#" @click.prevent="setHeatmap('original')">
                    <img :src="originalImage(current.submission)" class="img-fluid" />
                  </a>
                </li>
                <li class="col-6 px-1 mt-2">
                  <a :class="selectedHeatmap === 'bounded' ? 'active' : ''" href="#" @click.prevent="setHeatmap('bounded')">
                    <img :src="boundingBoxes(current.submission)" class="img-fluid" />
                  </a>
                </li>
              </ul>
            </b-tab>
              <b-tab title="Heatmap view">
                <ul class="list-unstyled row mx-n1 heatmap-list">
                  <li class="col-3 px-1 mt-2" v-for="heatmap in current.heatmaps" :key="heatmap.filename">
                    <a :class="selectedHeatmap === heatmap.filename ? 'active' : ''" href="#" @click.prevent="setHeatmap(heatmap.filename)">
                      <img :src="'data:image/png;base64,' + heatmap.image" class="img-fluid" />
                    </a>
                  </li>
                </ul>
              </b-tab>
            </b-tabs>
            <hr class="border-blue" />
            <b-form>
              <h6 class="color-white mb-3">Enhance image</h6>
              <b-form-group>
                <label class="color-white mb-0">Brightness</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="brightness" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Contrast</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="contrast" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Saturation</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="saturate" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Sepia</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="sepia" type="range" min="0" max="100" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Invert</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="invert" type="range" min="0" max="100" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
        </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { apiRoute } from './../../../helpers'

export default {
  data () {
    return {
      selectedHeatmap: 'original',
      key: 1,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0
    }
  },
  props: {
    current: {
      type: Object,
      default: null
    },
    model: String
  },
  computed: {
    selectedImage () {
      if (this.selectedHeatmap) {
        if (this.selectedHeatmap === 'original') {
          return this.originalImage(this.current.submission)
        } else if (this.selectedHeatmap === 'bounded') {
          return this.boundingBoxes(this.current.submission)
        } else {
          const _image = this.current.heatmaps.find(heatmap => heatmap.filename === this.selectedHeatmap)
          if (_image) {
            return 'data:image/png;base64,' + _image.image
          } else {
            return null
          }
        }
      } else {
        return null
      }
    },
    filteredList () {
      if (this.current.analysis.pathologies && this.current.analysis.pathologies.length > 0) {
        const _list = this.current.analysis.pathologies.filter(_pathology => _pathology.name !== 'Thoracic' && _pathology.name !== 'Cardio')
        return _list
      } else {
        return []
      }
    }
  },
  methods: {
    setHeatmap: function (name) {
      this.selectedHeatmap = name
    },
    getConclusion: function (name) {
      if (this.model === 'General') {
        return this.getGeneralConclusion(name)
      } else if (this.model === 'Tuberculosis') {
        return this.getTuberculosisConclusion(name)
      } else if (this.model === 'Pneumonia') {
        return this.getPneumoniaConclusion(name)
      } else {
        return this.getGeneralConclusion(name)
      }
    },
    getGeneralConclusion: function (name) {
      return 'Abnormality score: ' + (this.current.analysis.score).toFixed(2)
    },
    getTuberculosisConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability of Tuberculosis'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability of Tuberculosis'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability of Tuberculosis'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getPneumoniaConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability of Pneumonia'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability of Pneumonia'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability of Pneumonia'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'exclamation-triangle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'exclamation-triangle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high' + ' ' + this.model.toLowerCase()
      } else if (name.indexOf('intermediate') > -1) {
        return 'moderate' + ' ' + this.model.toLowerCase()
      } else if (name.indexOf('low') > -1) {
        return 'moderate' + ' ' + this.model.toLowerCase()
      } else {
        return 'nothing' + ' ' + this.model.toLowerCase()
      }
    },
    originalImage: function (result) {
      if (result) {
        if (result.attachments[0].path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].path
        } else {
          return result.attachments[0].path
        }
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path
        } else {
          return result.attachments[0].response.bounding.path
        }
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'Cardio')
        if (_pathology) {
          if (_pathology.confidence > 0.5) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
