<template>
  <b-card no-body class="h-100 bg-dark-navy custom border-0" v-if="current">
    <template v-slot:header>
      <b-row>
        <b-col>
          <h6 class="color-white">{{ current.submission.patient.demographics.name || current.submission.patient.identifier }}</h6>
          <b-badge pill class="indicator px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
            <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon> {{ getConclusion(current.analysis.conclusion.toLowerCase()) }}
          </b-badge>
        </b-col>
        <b-col class="text-right">
          <b-button-group size="sm" v-if="isStaff">
            <b-button class="font-weight-400 bg-transparent" @click.prevent="openAnalysis()">
              Analysis &amp; Report <font-awesome-icon icon="file-alt" class="ml-1"></font-awesome-icon>
            </b-button>
          </b-button-group>
          <b-button-group size="sm">
            <b-button class="font-weight-400 bg-transparent" v-b-modal.modal-fullscreen>
              Fullscreen <font-awesome-icon icon="expand-arrows-alt" class="ml-1"></font-awesome-icon>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="h-100 p-0">
      <b-tabs card pills class="h-100" nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0" content-class="overflow-y scroll-overflow h-100" fill>
        <b-tab active title="Image view" class="p-3">
          <b-row>
            <b-col>
              <lightbox :album="current.submission.patient.identifier" :src="originalImage(current.submission)" caption="Original Image">
                <img class="img img-fluid pr-1 mb-3" :src="originalImage(current.submission)" />
              </lightbox>
            </b-col>
            <b-col>
              <lightbox :album="current.submission.patient.identifier" :src="boundingBoxes(current.submission)" caption="Augmented Image">
                <img class="img img-fluid pr-1 mb-3" :src="boundingBoxes(current.submission)" />
              </lightbox>
            </b-col>
          </b-row>
          <div class="table-responsive">
            <table class="table table-borderless table-sm color-white mb-0" width="100%">
              <thead>
                <tr class="border-bottom border-blue">
                  <th width="180" class="pl-0 font-size-18">
                    Parenchyma
                  </th>
                  <th class="text-center pl-0">RUZ</th>
                  <th class="text-center pl-0">RMZ</th>
                  <th class="text-center pl-0">RLZ</th>
                  <th class="text-center pl-0">LUZ</th>
                  <th class="text-center pl-0">LMZ</th>
                  <th class="text-center pl-0">LLZ</th>
                  <th class="text-center pl-0">RT</th>
                  <th class="text-center pl-0">LT</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pathology in filteredList" :key="current.submission._id + '_' + pathology.name">
                  <td>
                    <font-awesome-icon :icon="pathology.rt || pathology.lt ? 'check-circle' : 'times-circle'" class="color-yellow mr-2 font-size-18"></font-awesome-icon>
                    {{ pathology.name | formatPathologyName(options) }}
                  </td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.ruz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.ruz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.rmz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.rmz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.rlz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.rlz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.luz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.luz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.lmz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.lmz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.llz ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.llz}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.rt ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.rt}"></font-awesome-icon></td>
                  <td class="text-center"><font-awesome-icon :icon="pathology.lt ? 'check-circle' : 'times-circle'" class="font-size-18 color-white" :class="{'color-yellow': pathology.lt}"></font-awesome-icon></td>
                </tr>
                <tr v-if="hasCardiomegaly(current.submission)">
                  <td>
                    <font-awesome-icon :icon="hasCardiomegaly(current.submission) ? 'check-circle' : 'times-circle'" class="color-yellow mr-2 font-size-18"></font-awesome-icon>
                    Heart enlagement
                  </td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Heatmap view" :key="key">
          <b-row v-if="current.heatmaps && current.heatmaps.length > 0">
            <b-col cols="9">
              <img v-if="selectedImage" :src="'data:image/png;base64,' + selectedImage.image" class="img-fluid" />
            </b-col>
            <b-col cols="3">
              Select heatmap type
              <ul class="list-unstyled row mx-n1 heatmap-list">
                <li class="col-6 px-1 mt-2" v-for="heatmap in current.heatmaps" :key="heatmap.filename">
                  <a :class="selectedHeatmap === heatmap.filename ? 'active' : ''" href="#" @click.prevent="setHeatmap(heatmap.filename)">
                    <img :src="'data:image/png;base64,' + heatmap.image" class="img-fluid" />
                  </a>
                </li>
              </ul>
            </b-col>
          </b-row>
          <b-row v-else>
            <h6 class="color-white px-3">Generating heatmaps. Please wait a little ...</h6>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card-body>
    <template v-slot:footer v-if="model === 'Pneumonia'">
      <b-row>
        <b-col>
          <b-badge pill class="indicator px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
            <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon> {{ getConclusion(current.analysis.conclusion.toLowerCase()) }}
          </b-badge>
        </b-col>
        <b-col class="text-right">
          <p class="color-yellow mb-0" v-if="current.analysis.conclusion">Abnormality Score: <strong class="color-white">{{ (current.analysis.score).toFixed(2) }}</strong></p>
        </b-col>
      </b-row>
      <!--
      <h6 class="color-yellow" v-if="getPneumoniaDescription(current.analysis.conclusion)">Conclusion</h6>
      <strong class="color-white">{{ getConclusion(current.analysis.conclusion.toLowerCase()) }} of Pneumonia</strong><br />
      {{ getPneumoniaDescription(current.analysis.conclusion, current.analysis.class) }}
      <h6 class="color-yellow mt-3" v-if="getPneumoniaDescription(current.analysis.conclusion, current.analysis.class)">Abnormality Score: <small><strong class="color-white">{{ (current.analysis.score).toFixed(2) }}</strong></small></h6>
      -->
    </template>
    <template v-slot:footer v-else-if="model === 'Tuberculosis'">
      <b-row v-if="getTuberculosisDescription(current.analysis.conclusion)">
        <b-col>
          <b-badge pill class="indicator px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
            <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon> {{ getTuberculosisConclusion(current.analysis.conclusion.toLowerCase()) }}
          </b-badge>
        </b-col>
        <b-col class="text-right">
          <p class="color-yellow mb-0" v-if="current.analysis.conclusion">Abnormality Score: <strong class="color-white">{{ (current.analysis.score).toFixed(2) }}</strong></p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-badge pill class="indicator px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
            <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon> {{ getConclusion(current.analysis.conclusion.toLowerCase()) }}
          </b-badge>
        </b-col>
        <b-col class="text-right">
          <p class="color-yellow mb-0" v-if="current.analysis.conclusion">Abnormality Score: <strong class="color-white">{{ (current.analysis.score).toFixed(2) }}</strong></p>
        </b-col>
      </b-row>
      <!--
      <h6 class="color-yellow" v-if="getTuberculosisDescription(current.analysis.conclusion)">Conclusion</h6>
      <strong class="color-white">{{ getConclusion(current.analysis.conclusion.toLowerCase()) }} of {{ current.analysis.type.toLowerCase() !== 'nil' ? current.analysis.type.charAt(0).toUpperCase() + current.analysis.type.substring(1) : null }} Tuberculosis</strong><br />
      {{ getTuberculosisDescription(current.analysis.conclusion) }}
      <h6 class="color-yellow mt-3" v-if="getTuberculosisDescription(current.analysis.conclusion)">Abnormality Score: <small><strong class="color-white">{{ (current.analysis.score).toFixed(2) }}</strong></small></h6>
      -->
    </template>
    <template v-slot:footer v-else-if="model === 'General'">
      <b-row>
        <b-col>
          <b-badge pill class="indicator px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
            <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon> {{ getConclusion(current.analysis.conclusion.toLowerCase()) }}
          </b-badge>
        </b-col>
      </b-row>
    </template>

    <analysis :current="current" :model="model" v-if="isStaff && analysisOpen" v-on:close-analysis="closeAnalysis"></analysis>
    <full-screen :current="current" :model="model"></full-screen>
  </b-card>
</template>

<script>
import Lightbox from 'vue-lightbox'
import { apiRoute, authHeader } from './../../../helpers'
import { mapState } from 'vuex'

import Analysis from './Analysis'
import FullScreen from './FullScreen'

export default {
  components: {
    Analysis,
    FullScreen,
    Lightbox
  },
  data () {
    return {
      current: null,
      selectedHeatmap: null,
      key: 1,
      analysisOpen: false
    }
  },
  props: {
    model: String,
    selected: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      options: state => state.pathologies.options
    }),
    isStaff () {
      return this.auth && this.auth.user ? this.auth.user.staff : false
    },
    selectedImage () {
      if (this.selectedHeatmap) {
        return this.current.heatmaps.find(heatmap => heatmap.filename === this.selectedHeatmap)
      } else {
        return null
      }
    },
    filteredList () {
      if (this.current.report && this.current.report.parenchyma && this.current.report.parenchyma.length > 0) {
        const _list = []
        this.current.report.parenchyma.forEach(_pathology => {
          _list.push({
            name: _pathology.name.replace(/[^a-zA-Z]/g, '').toLowerCase(),
            ruz: _pathology.areaInvolved.rul,
            rmz: _pathology.areaInvolved.rml,
            rlz: _pathology.areaInvolved.rll,
            luz: _pathology.areaInvolved.lul,
            lmz: _pathology.areaInvolved.ling,
            llz: _pathology.areaInvolved.lll,
            rt: _pathology.areaInvolved.right,
            lt: _pathology.areaInvolved.left
          })
        })

        return _list
      } else if (this.current.analysis.pathologies && this.current.analysis.pathologies.length > 0) {
        const _list = []
        this.current.analysis.pathologies.filter(_pathology => _pathology.name !== 'Thoracic' && _pathology.name !== 'Cardio').forEach(_pathology => {
          _list.push({
            name: _pathology.name.replace(/[^a-zA-Z]/g, '').toLowerCase(),
            ruz: _pathology.ruz,
            rmz: _pathology.rmz,
            rlz: _pathology.rlz,
            luz: _pathology.luz,
            lmz: _pathology.lmz,
            llz: _pathology.llz,
            rt: _pathology.rt ? _pathology.rt : (_pathology.ruz || _pathology.rmz || _pathology.rlz),
            lt: _pathology.lt ? _pathology.lt : (_pathology.luz || _pathology.lmz || _pathology.llz)
          })
        })
        return _list
      } else {
        return []
      }
    }
  },
  created () {
    this.current = null
  },
  async mounted () {
    if (this.selected) {
      this.current = this.selected

      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
      const _response = await fetch(apiRoute() + '/api/v1/staff/pneumonia/heatmaps/' + this.current.submission._id, requestOptions)
      const results = await _response.json()
      this.current.heatmaps = results
      this.key += 1
      if (results && results.length > 0) {
        this.selectedHeatmap = results[0].filename
      }
    }
  },
  methods: {
    openAnalysis: function () {
      this.analysisOpen = true
      this.$nextTick(() => {
        this.$bvModal.show('modal-analysis')
      })
    },
    closeAnalysis: function () {
      this.analysisOpen = false
    },
    setHeatmap: function (name) {
      this.selectedHeatmap = name
    },
    getConclusion: function (name) {
      if (this.model === 'General') {
        return this.getGeneralConclusion(name)
      } else if (this.model === 'Tuberculosis') {
        return this.getTuberculosisConclusion(name)
      } else if (this.model === 'Pneumonia') {
        return this.getPneumoniaConclusion(name)
      } else {
        return this.getGeneralConclusion(name)
      }
    },
    getGeneralConclusion: function (name) {
      return 'Abnormality score: ' + (this.current.analysis.score).toFixed(2)
    },
    getTuberculosisConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability of Tuberculosis'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability of Tuberculosis'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability of Tuberculosis'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getPneumoniaConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability of Pneumonia'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability of Pneumonia'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability of Pneumonia'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'exclamation-triangle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'exclamation-triangle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high' + ' ' + this.model.toLowerCase()
      } else if (name.indexOf('intermediate') > -1) {
        return 'moderate' + ' ' + this.model.toLowerCase()
      } else if (name.indexOf('low') > -1) {
        return 'moderate' + ' ' + this.model.toLowerCase()
      } else {
        return 'nothing' + ' ' + this.model.toLowerCase()
      }
    },
    getPneumoniaDescription: function (conclusion, classcode) {
      if (conclusion.indexOf('High') > -1) {
        if (classcode === 1) {
          return 'Typical appearance of Consolidation with /without Infiltrates, Pleural Effusion, Cavity and /or Ground Glass.'
        } else if (classcode === 2) {
          return 'Typical appearance of Infiltrates in two or more zones.'
        } else if (classcode === 3) {
          return 'Typical appearance of Infiltrates and Ground Glass.'
        } else {
          return 'Typical appearance of Consolidation and /or Infiltrates, Cavity and /or Ground Glass.'
        }
      } else if (conclusion.indexOf('Intermediate') > -1) {
        if (classcode === 1) {
          return 'Typical appearance of Infiltrates confined to a single zone with /without Atelectasis.'
        } else if (classcode === 2) {
          return 'Typical appearance of large Pleural Effusion.'
        } else if (classcode === 3) {
          return 'Typical appearance of Ground Glass without Consolidation and /or Infiltrates.'
        } else if (classcode === 4) {
          return 'Typical appearance of Infiltrates confined to a single zone with /without Atelectasis.'
        } else {
          return 'Typical appearance of Infiltrates, Pleural Effusion, Ground Glass and /or Atelectasis.'
        }
      } else if (conclusion.indexOf('Low') > -1) {
        if (classcode === 1) {
          return 'Typical appearance of small Pleural Effusion.'
        } else if (classcode === 2) {
          return 'Typical appearance of very small Infiltrates in one or more zones.'
        } else if (classcode === 3) {
          return 'Typical appearance of Atelectasis without Consolidation and /or Infiltrates.'
        } else if (classcode === 4) {
          return 'Typical appearance of Cavity without Consolidation and /or Infiltrates.'
        } else {
          return 'Typical appearance of very small Infiltrates, small Pleural Effusion, Cavity and /or Atelectasis.'
        }
      } else if (conclusion.indexOf('Other') > -1) {
        return 'Does not fit into classic or probable. Patterns on chest x-ray suggest alternative diagnosis.'
      } else {
        return null
      }
    },
    getTuberculosisDescription: function (conclusion) {
      if (conclusion.indexOf('High') > -1) {
        return 'Typical appearance of Lymphadenopathy or Lymphadenopathy with Consolidation and/or Infiltrates, Cavity, Pleural Effusion, Nodules. Features of Fibrosis and/or Bronchiectasis with or without Consolidation, Nodules or Cavity.'
      } else if (conclusion.indexOf('Intermediate') > -1) {
        return 'Typical appearance of Bilateral Infiltrates and/or Bilateral Consolidation, Pleural Effusion, Bilateral Nodules, Consolidation or Pleural Effusion.'
      } else if (conclusion.indexOf('Low') > -1) {
        return 'Typical features of Lobar Consolidation, Infiltrates or Pleural Effusion, or features not consistent with High Probability or Intermediate Probability.'
      } else {
        return 'Does not fit into classic or probable. Patterns on chest x-ray suggest alternative diagnosis.'
      }
    },
    originalImage: function (result) {
      if (result) {
        if (result.attachments[0].path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].path
        } else {
          return result.attachments[0].path
        }
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path + '?time=' + (new Date()).toISOString()
        } else {
          return result.attachments[0].response.bounding.path + '?time=' + (new Date()).toISOString()
        }
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'Cardio')
        if (_pathology && _pathology.confidence && _pathology.confidence >= 0.5) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
