<script>
export default {
  inject: ['provider'],
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    w: {
      type: Number,
      default: 0
    },
    pathology: {
      type: String,
      default: ''
    },
    ratio: {
      type: Number,
      default: 0
    },
    colour: {
      type: String,
      default: ''
    }
  },
  // eslint-disable-next-line
  render () {
    if (!this.provider.context) return
    const ctx = this.provider.context
    const _pathology = this.pathology.charAt(0).toUpperCase() + this.pathology.substring(1)

    ctx.shadowOffsetX = 2
    ctx.shadowOffsetY = 2
    ctx.shadowBlur = 4
    ctx.shadowColor = this.colour

    ctx.lineWidth = 2
    ctx.strokeStyle = this.colour
    ctx.strokeRect(this.x, this.y, this.w, this.h)
    ctx.font = '14px "Lato"'
    var _title = ctx.measureText(_pathology)
    ctx.beginPath()
    ctx.moveTo(this.x, this.y + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
    ctx.lineTo(this.x, this.y - _title.fontBoundingBoxDescent)
    ctx.moveTo(this.x + this.w, this.y + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
    ctx.lineTo(this.x + this.w, this.y - _title.fontBoundingBoxDescent)
    ctx.moveTo(this.x, this.y)
    ctx.lineTo(this.x + this.w, this.y)
    ctx.stroke()

    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0
    ctx.shadowBlur = 0

    ctx.fillStyle = ctx.strokeStyle = this.colour
    if (_pathology.toLowerCase() === 'cardio') {
      const _newPathology = _pathology + ', CTR: ' + (this.ratio * 100).toFixed(2) + '%'
      ctx.fillText(_newPathology, this.x + 5, this.y + _title.fontBoundingBoxAscent - 1, this.w)
    } else {
      ctx.fillText(_pathology, this.x + 5, this.y + _title.fontBoundingBoxAscent - 1, this.w)
    }
  }
}
</script>
