<script>
export default {
  inject: ['provider'],
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    w: {
      type: Number,
      default: 0
    },
    h: {
      type: Number,
      default: 0
    },
    pathology: {
      type: String,
      default: ''
    },
    confidence: {
      type: Number,
      default: 0
    },
    colour: {
      type: String,
      default: ''
    }
  },
  // eslint-disable-next-line
  render () {
    if (!this.provider.context) return
    const ctx = this.provider.context
    const _pathology = this.pathology

    ctx.shadowOffsetX = 2
    ctx.shadowOffsetY = 2
    ctx.shadowBlur = 4
    ctx.shadowColor = this.colour

    ctx.lineWidth = 2
    ctx.strokeStyle = this.colour
    ctx.strokeRect(this.x, this.y, this.w, this.h)
    ctx.font = '14px "Lato"'
    var _title = ctx.measureText(_pathology)
    ctx.fillStyle = this.colour
    ctx.fillRect(this.x, this.y, this.w, _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)

    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0
    ctx.shadowBlur = 0

    ctx.fillStyle = ctx.strokeStyle = '#ffffff'
    ctx.fillText(_pathology, this.x + 5, this.y + _title.fontBoundingBoxAscent - 1, this.w)
    if (this.confidence) {
      ctx.font = '12px "Lato"'
      ctx.fillStyle = ctx.strokeStyle = this.colour
      ctx.fillText('AIC: ' + (this.confidence * 100).toFixed(2) + '%', this.x + 5, this.y + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent + 14, this.w)
    }
  }
}
</script>
