<template>
  <div id="bounded-image" style="position: absolute; top: 0; left: 0px; width: 100%; height: 100%" :style="{ left: l.toString() + 'px' }">
    <canvas ref="bounded-canvas"></canvas>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    w: {
      type: Number,
      default: 0
    },
    h: {
      type: Number,
      default: 0
    },
    l: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      provider: {
        context: null
      }
    }
  },
  provide () {
    return {
      provider: this.provider
    }
  },
  mounted () {
    this.provider.context = this.$refs['bounded-canvas'].getContext('2d')

    this.$refs['bounded-canvas'].width = this.w || this.$refs['bounded-canvas'].parentElement.clientWidth
    this.$refs['bounded-canvas'].height = this.h || this.$refs['bounded-canvas'].parentElement.clientHeight

    this.provider.context.clearRect(0, 0, this.$refs['bounded-canvas'].width, this.$refs['bounded-canvas'].height)
  },
  watch: {
    h: function (_new, _old) {
      this.$refs['bounded-canvas'].height = _new
    },
    w: function (_new, _old) {
      console.log('New w', _new, _old)
      this.$refs['bounded-canvas'].width = _new
    }
  }
}
</script>
